const sliderTestimonials = new Swiper('.swiper-testimonials', {
    // Optional parameters
    direction: 'horizontal',
    loop: true, 
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
});

const sliderLigues = new Swiper('.swiper-ligues', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    navigation: {
        nextEl: '.swiper-ligue-next',
        prevEl: '.swiper-ligue-prev'
    },
    slidesPerView: 1,
    breakpoints: {
        680: {
            slidesPerView: 2,
            spaceBetween: 40
        },
        840: {
            slidesPerView: 3,
            spaceBetween: 40
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 40
        }
    }
});



/* Scroll */
window.onscroll = function () { 
    headerScroll() 
};

function headerScroll() {
    if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        document.getElementById("header").classList.add('active');
    } else {
        document.getElementById("header").classList.remove('active');
    }
}



let selectParticiper = document.getElementById("select-participer");
let validSelectParticiper = document.getElementById("valid-select-participer"); 
if (typeof (validSelectParticiper) != 'undefined' && validSelectParticiper != null) {
    validSelectParticiper.addEventListener("click", function () {
        window.open(selectParticiper.value, '_blank').focus();
    });
}


let videoHome = document.getElementById("video-home");
let videoPlayPause = document.getElementById("video-play-pause");
let videoMute = document.getElementById("video-mute");

if (typeof (videoMute) != 'undefined' && videoMute != null) {
    videoMute.addEventListener("click", function () {
        videoHome.muted = !videoHome.muted;
        videoMute.classList.toggle('actions__mute--muted')
    });
}
if (typeof (videoPlayPause) != 'undefined' && videoPlayPause != null) {
    videoPlayPause.addEventListener("click", function () {
        if (videoHome.paused){
            videoHome.play();
            videoPlayPause.classList.remove('actions__pause--paused')
        }
        else{
            videoHome.pause();
            videoPlayPause.classList.add('actions__pause--paused')
        }
    });
}


/* MAP  */
var map = document.querySelector('#map')
if (typeof (map) != 'undefined' && map != null) {
    var paths = map.querySelectorAll('.map__image a')
    var links = map.querySelectorAll('.map__list .cities__item')
    var selectRegion = document.querySelector('#select-region')
    var validselectRegion = document.querySelector('#valid-select-region')


    // Polyfill du foreach
    if (NodeList.prototype.forEach === undefined) {
        NodeList.prototype.forEach = function (callback) {
            [].forEach.call(this, callback)
        }
    }

    var activeArea = function (id) {
        map.querySelectorAll('.is-active').forEach(function (item) {
            item.classList.remove('is-active')
        })
        if (id !== undefined) {
            document.querySelectorAll('.list-' + id).forEach(function (city) {
                city.classList.add('is-active')
            })
            document.querySelector('#region-' + id).classList.add('is-active')
            selectRegion.value = id
        }
    }

    activeArea('A')

    paths.forEach(function (path) {
        path.addEventListener('click', function () {
            var id = this.id.replace('region-', '')
            activeArea(id)
        })
    })
    /*
    links.forEach(function (link) {
        link.addEventListener('mouseenter', function () {
            var id = this.id.replace('list-', '')
            activeArea(id)
        })
    })
    */
    validselectRegion.addEventListener('click', function () {
        var id = selectRegion.value
        activeArea(id)
    });
    /*
    selectRegion.addEventListener('change', function () {
        var id = this.value
        activeArea(id)
    });
    */

}

let buttonsContact = document.querySelectorAll('.button-contact');
let contact = document.getElementById('contact');
let contactClose = document.getElementById('contact__close');

for (var i = 0; i < buttonsContact.length; i++) {
    var buttonContact = buttonsContact[i];
    buttonContact.onclick = function () {
        contact.classList.add('active')
    }
}

contactClose.onclick = function () {
    contact.classList.remove('active');
}


/* Menu */
let openMenu = document.getElementById('open-menu');
let closeMenu = document.getElementById('close-menu');
let menu = document.getElementById('main-menu');

openMenu.onclick = function () {
    menu.classList.add('active');
    closeMenu.classList.add('active');
    console.log('menu click')
}

closeMenu.onclick = function () {
    menu.classList.remove('active');
    closeMenu.classList.remove('active');
}


/* SLIDE UP */
function slideUp(target, duration) {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(function () {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* SLIDE DOWN */
function slideDown(target, duration) {
    target.style.removeProperty('display');
    var display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    var height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(function () {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* SLIDE TOOGLE */
function slideToggle(target, duration) {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}


var showLigueDetails = document.querySelectorAll('.show-ligue-details');
Array.from(showLigueDetails).forEach(function (showLigueDetail) {
    showLigueDetail.onclick = function () {
        let theTitle = this.closest('.item__title');
        let theDetails = this.closest('.ligue').querySelectorAll('.js-ligue-details');
        let classementContainer = document.querySelector('#classement-container');

        if (!theTitle.classList.contains('active')) {
            let ligueTitles = document.querySelectorAll('.js-ligue-title');
            Array.from(ligueTitles).forEach(function (ligueTitle) {
                ligueTitle.classList.remove('active');
            });

            let ligueDetails = document.querySelectorAll('.js-ligue-details');
            Array.from(ligueDetails).forEach(function (ligueDetail) {
                slideUp(ligueDetail, 300);
            });

            Array.from(theDetails).forEach(function (theDetail) {
                setTimeout(function () { slideDown(theDetail, 300); }, 300);
            });


            theTitle.classList.add('active');

            if (window.innerWidth > 1023) {
                let classement = this.closest('.ligue').querySelector('.classement');
                classementContainer.classList.remove('default');
                classementContainer.innerHTML = "";
                classementContainer.appendChild(classement.cloneNode(true));

            }
        }
        else{
            let defaultText = classementContainer.dataset.default;
            classementContainer.innerHTML = defaultText;
            classementContainer.classList.add('default');
            theTitle.classList.remove('active');
            Array.from(theDetails).forEach(function (theDetail) {
                setTimeout(function () { slideUp(theDetail, 300); }, 300);
            });
        }
        
    }
});


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get('id');

if (id !== null){
    let ligue = document.getElementById('ligue-'+id);
    let theTitle = ligue.querySelector('.item__title');
    let theDetails = ligue.querySelector('.js-ligue-details');
    theTitle.classList.add('active');
    slideDown(theDetails, 300);
    if (window.innerWidth > 1023) {
        let classementContainer = document.querySelector('#classement-container');
        classementContainer.classList.remove('default');
        let classement = ligue.querySelector('.classement');
        classementContainer.innerHTML = "";
        classementContainer.appendChild(classement.cloneNode(true));
    }
    ligue.scrollIntoView(true);
    window.scrollBy(0, -200);
}


